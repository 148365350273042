import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/27402544c4dc363b2934fe1d77838859/12609/rr-daily-note.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.50000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB0ajkCkgl/8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIDEgAh/9oACAEBAAEFAs66ssTVwF0R1mJmPV//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAEBAAEFAAAAAAAAAAAAAAABAAIQERIhMf/aAAgBAQAGPwL1LJ5LA9aMb3//xAAaEAEBAQEAAwAAAAAAAAAAAAABABEhMUGR/9oACAEBAAE/IQB+Bg0xu9ugIL1YXfERIFy//9oADAMBAAIAAwAAABAg3//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EEZ//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQEQ/9oACAECAQE/EGGf/8QAGhABAQADAQEAAAAAAAAAAAAAAREAITFhwf/aAAgBAQABPxBmXDL8nASkjBpncVGMRe+4ARkd7MjnocPckxEqZ//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rr-daily-note",
            "title": "rr-daily-note",
            "src": "/static/27402544c4dc363b2934fe1d77838859/4b190/rr-daily-note.jpg",
            "srcSet": ["/static/27402544c4dc363b2934fe1d77838859/e07e9/rr-daily-note.jpg 200w", "/static/27402544c4dc363b2934fe1d77838859/066f9/rr-daily-note.jpg 400w", "/static/27402544c4dc363b2934fe1d77838859/4b190/rr-daily-note.jpg 800w", "/static/27402544c4dc363b2934fe1d77838859/e5166/rr-daily-note.jpg 1200w", "/static/27402544c4dc363b2934fe1d77838859/b17f8/rr-daily-note.jpg 1600w", "/static/27402544c4dc363b2934fe1d77838859/12609/rr-daily-note.jpg 3000w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The Daily Note can be daunting at first glance. It is just a blank screen with a blinking cursor. You can very easily run into a writer's block. But, It doesn't have to be like that. After the initial inertia, Roam actually prevents you from hitting that writer's block. It is designed in a way to promote thinking.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Using a template can be that silver bullet which allows to get started bvecause you always have something to write off of.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`To be honest, I would recommend you to develop your own template. But that isn't the easiest of things if you are a beginner in Roam Research. So, here is my template crafted carefully over the the 3 months I have been using Roam Research.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Template`}</p>
        <h2 parentName="li">{`Routine`}</h2>
        <ul parentName="li" {...{
          "className": "contains-task-list"
        }}>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Come upstairs`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`The 3-Minute Journal`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Read`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Write the morning pages`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Make some coffee`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Highlight time`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Review the Anki Cards`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Write for A Productive Nerd`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Breakfast`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Highlight time`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Coding`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Long Break`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Workout`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Audiobook`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`If anything is left time`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Make tomorrow's daily note`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`3-Minute Journal`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Journal`}</li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Plants care`}</li>
        </ul>
        <h2 parentName="li">{`Goal of the week`}</h2>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`[ ]`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`#[`}{`[Things to do]`}{`]`}</p>
            <ul parentName="li">
              <li parentName="ul">{`[ ]`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li">{`#[`}{`[Morning Pages]`}{`]`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`#[`}{`[Resonance Calendar]`}{`]`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`#Journal`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`#[`}{`[Quick Capture]`}{`]`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Functions and Rationale`}</p>
        <ul parentName="li">
          <li parentName="ul">{`#[`}{`[Things to do]`}{`]`}
            <ul parentName="li">
              <li parentName="ul">{`Function`}
                <ul parentName="li">
                  <li parentName="ul">{`Inside the #[`}{`[Things to do]`}{`], I keep the tasks that I want to accomplish that day. It is self explanatory. But there is a twist. I like to implement the tactic of "The Daily Highlight" from the book Make Time. I have it in the following format`}</li>
                  <li parentName="ul">{`^^{{[`}{`[TODO]`}{`]}} `}{`*`}<strong parentName="li">{`*`}{`The Daily Highlight`}{`*`}{`*`}</strong>{`^^`}</li>
                </ul>
              </li>
              <li parentName="ul">{`Rationale`}
                <ul parentName="li">
                  <li parentName="ul">{`The rationale is simple, I get together and write down all the things that I want to get done in one place.`}</li>
                </ul>
              </li>
            </ul>
          </li>
          <li parentName="ul">{`#[`}{`[Morning Pages]`}{`]`}
            <ul parentName="li">
              <li parentName="ul">{`Function`}
                <ul parentName="li">
                  <li parentName="ul">{`In side the Morning Pages, I write a numberi everything down that is on my mind`}</li>
                </ul>
              </li>
              <li parentName="ul">{`Rationale`}
                <ul parentName="li">
                  <li parentName="ul">{`The morning pages are 3 pages of longform writing. This has method's source can be found `}<a parentName="li" {...{
                      "href": "https://juliacameronlive.com/basic-tools/morning-pages/"
                    }}>{`here`}</a></li>
                  <li parentName="ul">{`It helps me get my thoughts in the morning together`}</li>
                </ul>
              </li>
            </ul>
          </li>
          <li parentName="ul">{`#Journal`}
            <ul parentName="li">
              <li parentName="ul">{`Function`}
                <ul parentName="li">
                  <li parentName="ul">{`Now comes the #Journal . In here, I write my journal of the day. I don't know the proper name that is used by the enthusiast journaling people. At the end of the day, I basically write an essay of how my day went, what I did and other stuff.`}</li>
                  <li parentName="ul">{`I also use the /time thing while I am going my day and type what I am doing and what my thoughts are`}</li>
                </ul>
              </li>
              <li parentName="ul">{`Rationale`}
                <ul parentName="li">
                  <li parentName="ul">{`The rationale behind Journal is that it allows me to concentrate my thoughts and organise them.`}</li>
                </ul>
              </li>
            </ul>
          </li>
          <li parentName="ul">{`#[`}{`[Quick Capture]`}{`]`}
            <ul parentName="li">
              <li parentName="ul">{`Function`}
                <ul parentName="li">
                  <li parentName="ul">{`Inside #[`}{`[Quick Capture]`}{`] , I simply put my thoughts of actionable things that I want to do later. This is used for the GTD method.`}</li>
                </ul>
              </li>
              <li parentName="ul">{`Rationale`}
                <ul parentName="li">
                  <li parentName="ul">{`My reason for keeping Quick Capture is to implement the GTD system. Anything that arises in my mind which I want to do later in my day is put in here.`}</li>
                </ul>
              </li>
            </ul>
          </li>
          <li parentName="ul">{`#[`}{`[Resonance Calendar]`}{`]`}
            <ul parentName="li">
              <li parentName="ul">{`Function`}
                <ul parentName="li">
                  <li parentName="ul">{`Inside the #[`}{`[Resonance Calendar]`}{`], I put every conetent that I consumed and thought was really good. I would recomend you to cehcl out Ali Abdaal's video on this. He is the person who popularised this.`}</li>
                </ul>
              </li>
              <li parentName="ul">{`Rationale`}
                <ul parentName="li">
                  <li parentName="ul">{`My rationale behind the Resonance Calender is simple. I consume content that I want to remember and to re-surface. I also do creative work and with the power of Roam Research I am able to produce content efficiently.`}</li>
                </ul>
              </li>
            </ul>
          </li>
          <li parentName="ul">{`Goal of the Week`}
            <ul parentName="li">
              <li parentName="ul">{`Function`}
                <ul parentName="li">
                  <li parentName="ul">{`The Goal of the week is the general direction of the theme that I want to go in my weak. It may be socialise which will encourage me to..socialise`}</li>
                </ul>
              </li>
              <li parentName="ul">{`Rationale`}
                <ul parentName="li">
                  <li parentName="ul">{`The rationale is that it makes my decision making better. If my goal is to socialise then it makes it easier for me to give up some work in order to socialise`}</li>
                </ul>
              </li>
            </ul>
          </li>
          <li parentName="ul">{`Routine`}
            <ul parentName="li">
              <li parentName="ul">{`Function`}
                <ul parentName="li">
                  <li parentName="ul">{`The routine is basically my schedule for the day. I just tick off the stuff in my routine that I have done. It helps me focus my energy and resources better.`}</li>
                </ul>
              </li>
              <li parentName="ul">{`Rationale`}
                <ul parentName="li">
                  <li parentName="ul">{`The routine helps me stay on track in terms of my day. It helps me in time-blocking which is a suprisingly underutilised technique but it can be life changing`}</li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      